<template>
  <div class="index" ref="viewRef">
    <custom-header
      :isChangeHead="isChangeHead"
      :isLoginUi="isContent"
      :header_left="header_left"
    ></custom-header>

    <router-view></router-view>

    <custom-footer></custom-footer>
    <el-backtop class="backtop" :bottom="33" :visibility-height="getHeight">
    <img src="" alt="">
  </el-backtop>

  </div>
</template>

<script>
export default {
  computed:{
    getHeight(){
      return document.documentElement.clientHeight-60
    }
  },
  created() {
    this.checkLoginViewIsShow();
    window.addEventListener("scroll", this.changeHead);
  },
  watch: {
    "$route.query.needLogin"() {
      this.checkLoginViewIsShow();
    },
    "$route.path"(newV, oldValue) {
      let dom = document.documentElement;
      if (newV === "/learn/detial" && oldValue == "/learn/home") {
        this.scrollIndex = dom.scrollTop;
      }
      dom.scrollTo(0, 0);
      if (oldValue === "/learn/detial" && newV == "/learn/home") {
        if (this.$route.query.isHome) return;
        let timer = setTimeout(() => {
          dom.scrollTop = this.scrollIndex;
          this.scrollIndex = 0;
          clearTimeout(timer);
        }, 0);
      }
      this.changeHead();
    },
  },
  data() {
    return {
      scrollIndex: 0,
      userInfoDate: {},
      isChangeHead: false,
      isContent: false,
      header_left: 0,
    };
  },
  methods: {
    checkLoginViewIsShow() {
      this.isContent = this.$route.query.needLogin ? true : false;
    },
    changeHead() {
      this.header_left = document.documentElement.scrollLeft;
      let flag =
        document.body.scrollHeight >
        (window.innerHeight || document.documentElement.clientHeight);
      if (flag) {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        if (scrollTop == 0) {
          //此时滚动条处于页面的顶部
          this.isChangeHead = false;
        } else {
          this.isChangeHead = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 100%;
  min-width: 1200px;
  height: calc(100vh);
  // overflow-y: auto;
  // overflow-x: hidden;
  .backtop {
    position: fixed;
    bottom: 33px;
    right: 40px;
    width: 48px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(18, 61, 101, 0.15);
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 13.4px;
      height: 16px;
      background-image: url("../../assets/backtop.svg");
      background-size: cover;
    }
  }
  .backtop:hover {
    background: #357aff;
    img {
      background-image: url("../../assets/backtops.svg");
      background-size: cover;
    }
  }
  .backtop:active {
    background: #2e6de7;
  }
}
</style>
